<template>
  <router-view />
</template>

<script>
import overviewModule from '../../store/overview'

export default {
  beforeCreate () {
    this.$store.registerModule('overview', overviewModule)
  },
  destroyed () {
    this.$store.unregisterModule('overview')
  }
}
</script>
