import {
  OVERVIEW_FETCH_REQUEST,
  OVERVIEW_FETCH_SUCCESS,
  OVERVIEW_FETCH_FAILURE
} from './types'

export default {
  fetch ({ commit }) {
    commit(OVERVIEW_FETCH_REQUEST)
    return window.axios.get(`/overview/statistics`).then(response => {
      commit(OVERVIEW_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(OVERVIEW_FETCH_FAILURE, error)
      throw error
    })
  },
}
