import {
  OVERVIEW_FETCH_REQUEST, OVERVIEW_FETCH_SUCCESS, OVERVIEW_FETCH_FAILURE
} from './types'

export default {
  [OVERVIEW_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [OVERVIEW_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [OVERVIEW_FETCH_FAILURE] (state) {
    state.loading = false
  },
}
